<template>
  <v-container v-if="hasPermission" fluid>
    <mex-heading content="Register System User"></mex-heading>
    <v-row justify="center">
      <v-col cols="9">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(validateRegisterForm)">
            <mex-card icon="mdi-account-plus">
              <v-row>
                <v-col cols="5">
                  <v-row justify="center">
                    <v-col cols="8">
                      <validation-provider v-slot="{ errors }" name="Username" rules="required">
                        <v-text-field
                          v-model="username"
                          :error-messages="errors"
                          label="Username"
                          outlined
                          autocomplete="off"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8">
                      <v-text-field
                        v-model="firstName"
                        label="First Name"
                        outlined
                        autocomplete="off"
                        type="text"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8">
                      <v-text-field
                        v-model="lastName"
                        label="Last Name"
                        outlined
                        autocomplete="off"
                        type="text"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8">
                      <validation-provider v-slot="{ errors }" name="E-Mail" rules="email">
                        <v-text-field
                          v-model="email"
                          :error-messages="errors"
                          label="E-Mail"
                          outlined
                          autocomplete="off"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8">
                      <v-switch
                        v-model="active"
                        color="secondary"
                        label="Active"
                        prepend-icon="mdi-alert-octagon"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="!active" justify="center">
                    <v-col cols="12">
                      <mex-sheet color="warning">
                        <v-row>
                          <v-col cols="1">
                            <v-icon>mdi-alert</v-icon>
                          </v-col>
                          <v-col>
                            <mex-p
                              alignment="center"
                              class="mb-0"
                              content="The user will not receive a registration email and will not be able to complete his registration until he is subsequently activated by an authorized user!"
                            />
                          </v-col>
                        </v-row>
                      </mex-sheet>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col>
                      <mex-p class="mb-0" content="User Roles:" />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col>
                      <role-treeview
                        :roles="roles"
                        :selectedRoles="selectedRoles"
                        :dividers="dividers"
                        displayFormat="checkbox"
                        @roleSelectionChanged="updateMyUserRoleArray"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!spinner" justify="center">
                <v-col cols="auto">
                  <mex-btn content="Register" :disabled="spinner" type="submit" />
                  <mex-btn content="Cancel" @click="cancelRegistration" />
                </v-col>
              </v-row>
              <v-row v-else justify="center">
                <v-progress-circular indeterminate/>
              </v-row>
            </mex-card>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import RoleTreeview from "../../components/LicSrvComponents/RoleTreeview.vue";
import SystemRolesService from "../../services/systemRoles.service";
import SystemUsersService from "../../services/systemUsers.service";
import requiredPermissions from "../../requiredPermissions";

export default {
  name: "RegisterUser.vue",
  components: {
    RoleTreeview
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"]),
    writeUsers() {
      return this.getUserPermissions.includes("write_System_User Role Administration_User");
    }
  },
  data() {
    return {
      roles: [],
      editMode: false,
      userID: -1,
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      selectedRoles: [],
      hasPermission: false,
      active: true,
      dividers: true,
      spinner: false,
    };
  },
  methods: {
    validateRegisterForm() {
      this.spinner = true;
      this.registerNewUser();
    },
    registerNewUser() {
      SystemUsersService.registerSystemUser(
        this.username,
        this.email,
        this.firstName,
        this.lastName,
        this.selectedRoles,
        this.active
      )
        .then(() => {
          this.$toast.success("New User Registered");
          this.$router.push({ name: "UsersOverview" });
        })
        .catch(err => {
          this.$toast.error(`Error while registering new user: ${err.response.data.message}`);
          this.spinner = false;
        });
    },
    fetchRoles() {
      SystemRolesService.getRoles().then(response => {
        this.roles = this.addValuesToRoles(response.data.roles);
      });
    },
    cancelRegistration() {
      this.$router.push({ name: "UsersOverview" });
    },
    updateMyUserRoleArray(value) {
      this.selectedRoles = value;
    },
    addValuesToRoles(roles) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].children.length !== 0) this.addValuesToRoles(roles[i].children);
        roles[i].disabled = false;
        roles[i].checked = false;
      }
      return roles;
    }
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.registerUser, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchRoles();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  }
};
</script>

<style scoped></style>
